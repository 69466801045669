import * as Yup from "yup";
import { ItemFormModel } from "../../../models/Item/ItemFormModel";
const digitosValidos = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "X",
  "W",
  "Y",
  "Z",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];
export const FormularioItemSchema: Yup.SchemaOf<ItemFormModel> =
  Yup.object().shape({
    id: Yup.string().notRequired(),
    codigo: Yup.string()
      .required("Item obrigatório")
      .test({
        test: (cod) => validaCodigoCupom(cod),
        message: "Código inválido",
      }),
    dataInicio: Yup.mixed()
      // .transform((data) => format(data, "dd/MM/yyyy"))
      .required("Item obrigatório"),
    dataFim: Yup.mixed()
      // .transform((data) => format(data, "dd/MM/yyyy"))
      .required("Item obrigatório"),
    valor: Yup.number().required("Item obrigatório").min(1, "Valor inválido"),
    descricao: Yup.string().required("Item obrigatório"),
  });

function validaCodigoCupom(codigo?: string): boolean {
  if (codigo) {
    const validarEspaco = codigo?.indexOf(" ") >= 0;
    if (validarEspaco) {
      return false;
    } else {
      return validaCodigoCupomEspecial(codigo);
    }
  } else {
    return false;
  }
}

function validaCodigoCupomEspecial(codigo: string): boolean {
  let validoCount = 0;
  for (let letra of codigo) {
    const valido = digitosValidos.indexOf(letra);
    if (valido === -1) {
      validoCount++;
    }
  }
  return validoCount === 0;
}
