import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { Controller, UseFormReturn } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { FooterToolbar } from "../../../components/FooterToolbar";
import { ItemFormModel } from "../../../models/Item/ItemFormModel";
import { Colors } from "../../../shared/colors";
import * as S from "./styles/index";

interface Props {
  open: boolean;
  onClose: () => void;
  salvar: (value: ItemFormModel) => void;
  useParamForm: UseFormReturn<ItemFormModel, object>;
  loading: boolean;
  edicao: boolean;
}

export const FormularioItem = ({
  open,
  onClose,
  salvar,
  useParamForm,
  loading,
  edicao,
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useParamForm;

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  return (
    <form>
      <S.StyledDrawer anchor="right" open={open} onClose={onClose}>
        <OptBackdrop open={loading} />
        <S.CabecalhoContainer>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <S.SpanText>
              {edicao ? "Editar cupom" : "Cadastro de cupom"}
            </S.SpanText>
          </OptActionButton>
        </S.CabecalhoContainer>
        <Divider />
        <S.GridContent container rowSpacing={1} columns={12} px={1.5}>
          <S.GridItem item xs={6}>
            <Controller
              control={control}
              name="dataInicio"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Data Início"
                  value={value ?? null}
                  onChange={onChange}
                  maxDate={watch("dataFim") ?? undefined}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
            <ErrorMessage error={errors.dataInicio} />
          </S.GridItem>
          <S.GridItem item xs={6}>
            <Controller
              control={control}
              name="dataFim"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label="Data Fim"
                  value={value ?? null}
                  onChange={onChange}
                  minDate={watch("dataInicio")}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            />
            <ErrorMessage error={errors.dataFim} />
          </S.GridItem>
          <S.GridItem item xs={6}>
            <Controller
              name="codigo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.TextFieldCustom
                  label="Código"
                  variant="outlined"
                  disabled={edicao}
                  onChange={(e) => {
                    const value = e.target.value;
                    onChange({
                      ...e,
                      target: { ...e.target, value: value.toUpperCase() },
                    });
                  }}
                  value={value}
                />
              )}
            />
            <ErrorMessage error={errors.codigo} />
          </S.GridItem>
          <S.GridItem item xs={6}>
            <Controller
              name="descricao"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.TextFieldCustom
                  label="Descrição"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMessage error={errors.descricao} />
          </S.GridItem>

          <S.GridItem item xs={6}>
            <Controller
              name="valor"
              control={control}
              render={({ field }) => (
                <NumericFormat
                  style={{ width: "100%" }}
                  suffix=" %"
                  label="Desconto"
                  min={0}
                  max={3}
                  maxLength={3}
                  customInput={TextField}
                  value={field.value ?? ""}
                  onValueChange={(values, source) => {
                    if (source.event) {
                      const { event } = source;
                      const { floatValue } = values;
                      const formartedValue = floatValue;
                      field.onChange({
                        ...event,
                        target: { ...event.target, value: formartedValue },
                      });
                    }
                  }}
                />
              )}
            />
            <ErrorMessage error={errors.valor} />
          </S.GridItem>
        </S.GridContent>

        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(salvar)}
            sx={sxButtonSaveConfig}
          >
            Salvar
          </Button>
        </FooterToolbar>
      </S.StyledDrawer>
    </form>
  );
};
