import { Grid } from "@mui/material";
import styled from "styled-components";

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const UlCuston = styled.ul`
  list-style-type: none;
`;

export const GridContent = styled(Grid)`
  /* margin-top: 2em; */
  float: right;
`;

export const GridItem = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;
  text-align: right;
`;

export const GridItemContent = styled.div`
  display: flex;
  /* width: 95%; */
  flex-direction: column;
`;
