export interface AlterarSenhaFormModel {
  senha: string;
  confirmeSenha: string;
}

export const ALTERAR_SENHA_FORM_INITIAL_STATE: AlterarSenhaFormModel = {
  senha: "",
  confirmeSenha: "",
};

export interface AlterarSenhaRequest {
  email: string;
  senha: string;  
}
