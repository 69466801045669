import { Grid } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";
import { LoginFormModel } from "../../models/login/loginFormModel";
import { Colors } from "../../shared/colors";
import * as S from "./styles/index";

interface Props {
  logar: (value: LoginFormModel) => void;
  useParamForm: UseFormReturn<LoginFormModel, object>;
}
export const LoginForm = ({ logar, useParamForm }: Props) => {
  const sxButtonConfig = {
    backgroundColor: "#000000",
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: "#403d3d",
    },
    width: "27em",
  };
  const { control, handleSubmit, watch } = useParamForm;

  const handleKeypress = (e: any) => {
    if (e.keyCode === 13 && watch("email") && watch("password")) {
      const request: LoginFormModel = {
        email: watch("email"),
        password: watch("password"),
      };
      logar(request);
    }
  };

  return (
    <S.FormCustom>
      <Grid container rowSpacing={1} columns={12}>
        <S.GridItemFormCustom item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.TextFieldCustom
                label="E-mail"
                variant="outlined"
                onChange={onChange}
                value={value}
                onKeyUp={handleKeypress}
              />
            )}
          />
        </S.GridItemFormCustom>
        <S.GridItemFormCustom item xs={12}>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <S.TextFieldCustom
                label="Senha de acesso"
                variant="outlined"
                onChange={onChange}
                value={value}
                type={"password"}
                onKeyUp={handleKeypress}
              />
            )}
          />
        </S.GridItemFormCustom>
        <S.GridItemFormCustom item xs={12}>
          <S.ButtonCustom
            size="large"
            onClick={handleSubmit(logar)}
            sx={sxButtonConfig}
            disabled={!watch("email") || !watch("password")}
          >
            Acessar
          </S.ButtonCustom>
        </S.GridItemFormCustom>
      </Grid>
    </S.FormCustom>
  );
};
