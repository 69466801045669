import { OptGridResponse, OptSearchResponse } from "@optsol/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../../contexts/auth";
// import { useAuthenticationContext } from "../../contexts/authentication/authenticationContext";
import { useUsuarioLocalStorageService } from "../../services/usuario.localstorage.service";
import { BaseConfig } from "../baseConfig";
import { IApiError } from "../types/ApiError";
import { ApiResponse } from "../types/ApiResponse";
import { SearchRequest } from "../types/SearchRequest";
import { useErrorSnackbar } from "./errorSnackbar";

export type UseApiOptions = {
  semToken?: boolean;
  external?: boolean;
};

export function useApi({ semToken, external }: Partial<UseApiOptions> = {}) {
  const { enqueueSnackbar } = useSnackbar();
  const { tratarErro } = useErrorSnackbar();
  const { obterToken } = useUsuarioLocalStorageService();
  const context = useAuth();

  const api = axios.create({
    baseURL: external ? "" : BaseConfig.baseApiUrl,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });

  api.interceptors.request.use(
    async (config) => {
      const configValida = config !== undefined && config.url !== undefined;

      if (configValida) {
        try {
          const token = obterToken();
          if (token && config.headers !== undefined) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        } catch (e) {
          console.error(e);
        }
      }

      return config;
    },
    (error) => {
      if (error.response.status === 403) {
        enqueueSnackbar(error + ": Forbidden", { variant: "error" });
        context.Logout();
      }

      if (error.response.status === 401) {
        enqueueSnackbar(error + ": unauthorized", { variant: "error" });
        context.Logout();
      }

      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (axiosResponse) => {
      const responseInvalida = !axiosResponse.data;

      if (responseInvalida) {
        console.info("Controller não retornou IActionResult!");
      }

      return axiosResponse.data;
    },
    (error: AxiosError<IApiError>) => {
      if (error.response?.status === 403) {
        tratarErro(error.response?.data);
        return Promise.reject(error.response?.data);
      }

      if (error.response?.status === 401) {
        enqueueSnackbar("Sua sessão expirou. Efetue o login novamente.", {
          variant: "error",
        });
        context.Logout();
        return Promise.reject(error.response?.data);
      }
      tratarErro(error.response?.data);
      return Promise.reject(error.response?.data);
    }
  );

  function gridSearch<T extends object>(
    url: string,
    data: SearchRequest<any>,
    config?: AxiosRequestConfig
  ) {
    data.page = data.page + 1;

    return api
      .post<T, OptSearchResponse<T>>(url, data, config)
      .then((response) => {
        const r: OptGridResponse<T> = {
          data: response.data,
          page: response.page - 1,
          totalCount: response.total,
        };

        return r;
      });
  }

  function search<T, R = OptSearchResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function post<T, R = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function put<T, R = ApiResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.put<T, R>(url, data, config);
  }

  function get<T, R = ApiResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return api.get<T, R>(url, config);
  }

  function getBlobFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  function postBlobFile<T, R = T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ) {
    return api.post<T, R>(url, data, config);
  }

  function remove<T, R = ApiResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ) {
    return api.delete<T, R>(url, config);
  }

  function getFile<T, R = T>(url: string, config?: AxiosRequestConfig) {
    return api.get<T, R>(url, config);
  }

  return {
    search,
    gridSearch,
    post,
    put,
    get,
    getFile,
    remove,
    getBlobFile,
    postBlobFile,
  };
}
