import { Button, Grid, TextField } from "@mui/material";
import { OptDialog, OptDialogActions, OptLoading } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import {
  AlterarSenhaFormModel,
  AlterarSenhaRequest,
} from "../../models/alterarSenha/AlterarSenhaFormModel";
import { UsuarioLoginResponse } from "../../models/dtos/UsuarioLoginResponse";
import { useUsuarioLocalStorageService } from "../../services/usuario.localstorage.service";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";

import * as S from "./styles/index";

interface AlterarSenhaDialogProps {
  usuario?: UsuarioLoginResponse;
  useParamForm: UseFormReturn<AlterarSenhaFormModel, object>;
  alterarSenha(value: AlterarSenhaRequest): Promise<void>;
}

export const AlterarSenhaDialog = ({
  usuario,
  useParamForm,
  alterarSenha,
}: AlterarSenhaDialogProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useParamForm;

  const [alterarSenhaLoading, setAlterarSenhaLoading] =
    useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const { obterUsuario, salvarDadosCache } = useUsuarioLocalStorageService();
  const { enqueueSnackbar } = useSnackbar();

  async function salvar(value: AlterarSenhaFormModel) {
    try {
      const request: AlterarSenhaRequest = {
        email: usuario!.email,
        senha: value.senha,
      };
      setAlterarSenhaLoading(true);
      await alterarSenha(request);

      const dadosUsuario = obterUsuario();
      dadosUsuario.isTemporaryPassword = false;
      salvarDadosCache(dadosUsuario);

      setAlterarSenhaLoading(false);
      setOpen(false);
      enqueueSnackbar(`Senha alterada com sucesso!`, {
        variant: "success",
      });
    } catch {
      setAlterarSenhaLoading(false);
    }
  }

  useEffect(() => {
    if (usuario?.isTemporaryPassword) {
      setOpen(true);
      setValue("senha", "");
      setValue("confirmeSenha", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <OptDialog title="Alteração de senha" open={open} onClose={() => {}}>
        <form>
          <Grid container rowSpacing={1} columns={12}>
            <Grid item xs={12}>
              <S.SenhaContent>
                <Controller
                  name="senha"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <S.TextFieldCustom
                      label="Nova senha"
                      variant="outlined"
                      type={"password"}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <Controller
                  name="confirmeSenha"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <S.TextFieldCustom
                      label="Confirme a senha"
                      variant="outlined"
                      type={"password"}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </S.SenhaContent>
              <ErrorMessage error={errors.senha} />
              <S.CheckListContent>
                <S.UlChekPassWord>
                  <S.LiCheckPassWord isValid={/.*[0-9]/.test(watch("senha"))}>
                    A senha deve possuir pelo menos um número.
                  </S.LiCheckPassWord>
                  <S.LiCheckPassWord isValid={/.*[a-z]/.test(watch("senha"))}>
                    A senha deve possuir ao menos uma letra minúscula.
                  </S.LiCheckPassWord>
                  <S.LiCheckPassWord isValid={/.*[A-Z]/.test(watch("senha"))}>
                    A senha deve possuir pelo menos uma letra maiúscula.
                  </S.LiCheckPassWord>
                  <S.LiCheckPassWord isValid={watch("senha")?.length >= 8}>
                    A senha deve possuir pelo menos 8 caracteres.
                  </S.LiCheckPassWord>
                  <S.LiCheckPassWord
                    isValid={
                      watch("senha") === watch("confirmeSenha") &&
                      watch("senha")?.length >= 8 &&
                      watch("confirmeSenha")?.length >= 8
                    }
                  >
                    As senhas devem ser iguais.
                  </S.LiCheckPassWord>
                </S.UlChekPassWord>
              </S.CheckListContent>
            </Grid>
          </Grid>
          <OptDialogActions style={{ height: "65px" }}>
            {alterarSenhaLoading ? (
              <OptLoading size={35} />
            ) : (
              <Button
                onClick={handleSubmit(salvar)}
                color="info"
                disabled={
                  !(
                    /.*[0-9]/.test(watch("senha")) &&
                    /.*[a-z]/.test(watch("senha")) &&
                    /.*[A-Z]/.test(watch("senha")) &&
                    watch("senha").length >= 8 &&
                    watch("senha") === watch("confirmeSenha") &&
                    watch("senha")?.length >= 8 &&
                    watch("confirmeSenha")?.length >= 8
                  )
                }
              >
                Alterar senha
              </Button>
            )}
          </OptDialogActions>
        </form>
      </OptDialog>
    </>
  );
};
