import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home/Home";
import { ItensRoutes } from "./Itens.routes";
import { SmsRoutes } from "./Sms.routes";
import { ViagensRoutes } from "./Viagens.routes";

export const Routes = {
  Home: "/",

  Itens: {
    Principal: "/itens",
  },
  Viagens: {
    Principal: "/viagens",
  },
  Sms: {
    Principal: "/sms",
  },
} as const;

export const routes = (
  <Switch>
    <Route exact path={Routes.Home} component={Home} />
    <Route exact path={Routes.Itens.Principal} component={ItensRoutes} />
    <Route exact path={Routes.Viagens.Principal} component={ViagensRoutes} />
    <Route exact path={Routes.Sms.Principal} component={SmsRoutes} />
    <Route path="*" component={Home} />
  </Switch>
);
