export interface ItemFormModel {
  id?: string;
  codigo: string;
  dataInicio: string | Date;
  dataFim: string | Date | null;
  valor?: number;
  descricao: string;
}

export const ITEM_FORM_INITIAL_STATE: ItemFormModel = {
  codigo: "",
  dataInicio: new Date(),
  dataFim: null,
  descricao: "",
};

export interface ViagemFiltro {
  dataInicio: string | Date;
  dataFim: string | Date;
}
