import { yupResolver } from "@hookform/resolvers/yup";
import { OptBackdrop, OptSideLayout } from "@optsol/react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AlterarSenhaDialog } from "../../components/AlterarSenhaDialog/AlterarSenhaDialog";
import { FormularioAlterarSenhaSchema } from "../../components/AlterarSenhaDialog/AlterarSenhaDialog.validation";
import AuthContext, { useAuth } from "../../contexts/auth";
import {
  AlterarSenhaFormModel,
  AlterarSenhaRequest,
  ALTERAR_SENHA_FORM_INITIAL_STATE,
} from "../../models/alterarSenha/AlterarSenhaFormModel";
import { routes } from "../../routes";
import { useUsuarioLocalStorageService } from "../../services/usuario.localstorage.service";
import { useUsuariosService } from "../../services/usuarios.service";
import { chatSquareIcon } from "../../shared/CustomIcons";
import { Login } from "../Login/Login";
import "./App.css";
import { useSections } from "./App.sections";

function App() {
  const sections = useSections();
  const { signed } = useContext(AuthContext);
  const context = useAuth();
  const { obterUsuario } = useUsuarioLocalStorageService();
  const dadosUsuario = obterUsuario();
  const { alterarSenhaUsuario } = useUsuariosService();

  const alterarSenhaForm = useForm<AlterarSenhaFormModel>({
    resolver: yupResolver(FormularioAlterarSenhaSchema),
  });

  async function alterarSenha(value: AlterarSenhaRequest): Promise<void> {
    await alterarSenhaUsuario(value);
  }

  useEffect(() => {
    alterarSenhaForm.reset(ALTERAR_SENHA_FORM_INITIAL_STATE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {<OptBackdrop open={context.isLoading} />}
      {!context.isLoading && signed ? (
        <>
          <AlterarSenhaDialog
            useParamForm={alterarSenhaForm}
            usuario={dadosUsuario}
            alterarSenha={alterarSenha}
          />
          <OptSideLayout
            sections={sections}
            routes={routes}
            onLogout={() => context.Logout()}
            onManageProfile={() => {}}
            profile={{
              name: dadosUsuario?.nome
                ? dadosUsuario.nome
                : dadosUsuario.email ?? "",
              email: dadosUsuario.email ?? "",
              avatarSrc: undefined,
            }}
            appBarConfig={{
              hideLinkDescription: true,
              actions: [
                {
                  icon: chatSquareIcon,
                  onClick: () => {
                    console.log("Icon Action!");
                  },
                  title: "Notificações",
                },
              ],
            }}
            version="v1.0"
          />
        </>
      ) : (
        <Login />
      )}
    </>
  );
}

export default App;
