import React, { createContext, useContext, useState } from "react";
import { UsuarioLoginResponse } from "../models/dtos/UsuarioLoginResponse";
import { LoginFormModel } from "../models/login/loginFormModel";
import { useUsuarioLocalStorageService } from "../services/usuario.localstorage.service";
import { useUsuariosService } from "../services/usuarios.service";

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
export const AuthProvider: React.FC = ({ children }) => {
  const { getAuth } = useUsuariosService();
  const { salvarDadosCache, obterUsuario } = useUsuarioLocalStorageService();

  const usuarioLogado = obterUsuario();
  const [user, setUser] = useState<UsuarioLoginResponse | null>(usuarioLogado);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function Login(data: LoginFormModel) {
    try {
      setIsLoading(true);
      const result = await getAuth(data);
      salvarDadosCache(result);
      setUser(result);
      setIsLoading(false);
    } catch {
      setIsLoading(false);
    }
  }

  function Logout() {
    salvarDadosCache(null);
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: verificarUsuario(user),
        user,
        Login,
        Logout,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;

function verificarUsuario(user: UsuarioLoginResponse | null): boolean {
  return Boolean(user);
}
interface AuthContextData {
  signed: boolean;
  user: UsuarioLoginResponse | null;
  Login(data: LoginFormModel): Promise<void>;
  Logout(): void;
  isLoading: boolean;
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
