import { Button, Grid, TextField } from "@mui/material";
import styled from "styled-components";
import { Colors } from "../../../shared/colors";

export const GridContent = styled(Grid)`
  height: 100vh;
  margin: 0;
`;

export const ImgSize = styled.img`
  width: 80%;
  height: 100%;

  @media (max-width: 898px) {
    display: none;
  }
`;

export const GridItemBannerCustom = styled(Grid)`
  background: black;
  text-align: center;
  /* width: 100%;
  height: 100%;
  padding-top: 23%; */
  @media (max-width: 898px) {
    display: none;
  }
`;

export const GridItemFormCustom = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DivMainContent = styled.div`
  overflow: hidden;
`;

export const FormTitle = styled.div`
  margin: 10px 0;
  width: 80%;
  max-width: 25em;
`;

export const FormCustom = styled.form`
  width: 100%;
`;

export const TextFieldCustom = styled(TextField)`
  width: 80%;
  max-width: 25em;
`;

export const ButtonCustom = styled(Button)`
  width: 80%;
  max-width: 26.5em;

  &.MuiButtonBase-root:disabled {
    background-color: ${Colors.gray8};
  }
`;
