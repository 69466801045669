import * as Yup from "yup";
import {
  Passageiro,
  SmsPontosDeEmbarqueSubmit,
  SmsSubmitModel,
} from "../../../models/sms/smsFormModel";

const PassageiroSchema: Yup.SchemaOf<Passageiro> = Yup.object().shape({
  passageiroId: Yup.number().required("Campo obrigatório"),
  telefone: Yup.string().required("Campo obrigatório"),
});

const PontosDeEmbarqueSchema: Yup.SchemaOf<SmsPontosDeEmbarqueSubmit> =
  Yup.object().shape({
    cidadeOrigemId: Yup.number().required("Campo obrigatório"),
    eventoId: Yup.number().required("Campo obrigatório"),
    dataHoraKey: Yup.string().required("Campo obrigatório"),
  });

export const FormularioSmsSchema: Yup.SchemaOf<SmsSubmitModel> =
  Yup.object().shape({
    mensagem: Yup.string().required("Campo obrigatório"),
    eventoId: Yup.array().required("Campo obrigatório"),
    pontosEmbarque: Yup.array()
      .of(PontosDeEmbarqueSchema)
      .required("Campo obrigatório"),
    passageirosSelecionados: Yup.array()
      .of(PassageiroSchema)
      .required("Campo obrigatório"),
    todoFiltro: Yup.boolean().required("Campo obrigatório"),
  });
