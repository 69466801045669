import { Button } from "@mui/material";
import styled from "styled-components";
import { Colors } from "./../../../shared/colors";

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin: 2em 0; */
`;

export const ButtonAction = styled(Button)`
  background-color: ${Colors.primary};
  color: ${Colors.white};
  font-family: "IBM Plex Sans";
  &:hover {
    background-color: ${Colors.primaryTints.tint1};
  }
`;
