import { mdiMagnify } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, TextField, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { OptActionToolbar, OptGridRef, OptGridRequest } from "@optsol/react";
import { useSnackbar } from "notistack";
import { useRef, useState } from "react";
import { OptGridContainer } from "../../components/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../components/OptSideLayoutContentCustom/OptSideLayoutContentCustom";
import { PaginatedViagemSearchRequest } from "../../models/search/PaginatedSearchRequest";
import { Routes } from "../../routes";
import { useViagensService } from "../../services/viagens.service";
import { Colors } from "../../shared/colors";
import { SearchRequest } from "../../shared/types/SearchRequest";
import { GridViagensView } from "./GridViagensView";
import * as S from "./styles/index";

export const GridViagens = () => {
  const { buscarGridViagensPaginated } = useViagensService();
  const [dataInicio, setDataInicio] = useState<Date | null>(null);
  const [dataFim, setDataFim] = useState<Date | null>(null);

  const carregar = (
    query: OptGridRequest,
    viagemSearchRequest: PaginatedViagemSearchRequest = {
      dataInicio: dataInicio,
      dataFim: dataFim,
      usuarioId: null,
    }
  ) => {
    const request: SearchRequest<PaginatedViagemSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: viagemSearchRequest,
    };

    return buscarGridViagensPaginated(request);
  };

  const gridRef = useRef<OptGridRef>(null);
  const { enqueueSnackbar } = useSnackbar();

  function procurarViagem() {
    if (!dataInicio && dataFim) {
      enqueueSnackbar("Informe uma data de saída", {
        variant: "warning",
      });
    } else if (!dataFim && dataInicio) {
      enqueueSnackbar("Informe uma data de retorno", {
        variant: "warning",
      });
    } else {
      gridRef.current?.refresh();
    }
  }

  return (
    <>
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Routes.Home}
          title="Visualização de viagens"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <form>
          <OptGridContainer>
            <S.ButtonSection style={{ marginBottom: "12px" }}>
              <Tooltip title={"Filtrar viagens"}>
                <Button
                  style={{ marginRight: "-15px", marginBottom: "-6.5px" }}
                  placeholder="Filtrar viagem"
                  size="large"
                  onClick={procurarViagem}
                >
                  <Icon path={mdiMagnify} size={1} color={Colors.primary} />
                </Button>
              </Tooltip>
              <DatePicker
                maxDate={dataFim}
                label="Data de Saída"
                value={dataInicio}
                onChange={(d) => setDataInicio(d)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-root > fieldset": {
                        borderColor: Colors.gray10,
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-focused) > fieldset":
                        {
                          borderColor: Colors.gray8,
                        },
                    }}
                    {...params}
                  />
                )}
              />
              <DatePicker
                minDate={dataInicio}
                label="Data de Retorno"
                value={dataFim}
                onChange={(d) => setDataFim(d)}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-root > fieldset": {
                        borderColor: Colors.gray10,
                      },
                      "& .MuiInputBase-root:hover:not(.Mui-focused) > fieldset":
                        {
                          borderColor: Colors.gray8,
                        },
                    }}
                    {...params}
                  />
                )}
              />
            </S.ButtonSection>
          </OptGridContainer>
        </form>
        <GridViagensView carregar={carregar} ref={gridRef} />
      </OptSideLayoutContentCustom>
    </>
  );
};
