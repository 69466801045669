import { mdiBusMarker, mdiViewList, mdiMessageTextOutline } from "@mdi/js";
import { OptMenuSection } from "@optsol/react";
// import { useAuthenticationContext } from "../../contexts/authentication/authenticationContext";
// import { SectionsClaims } from "../../contexts/claims/sectionsClaims";
import { squadIcon } from "../../shared/CustomIcons";

export const useSections = () => {
  // const { hasAccess } = useAuthenticationContext();

  const sections: OptMenuSection[] = [
    {
      title: "",
      items: [
        {
          icon: squadIcon,
          path: "",
          title: "",
        },
      ],
    },
  ];

  const protectedItems: OptMenuSection = {
    title: "Protected",
    items: [],
  };

  protectedItems.items.push({
    icon: mdiViewList,
    path: "/itens",
    title: "Cupom",
  });

  protectedItems.items.push({
    icon: mdiBusMarker,
    path: "/viagens",
    title: "Viagem",
  });

  protectedItems.items.push({
    icon: mdiMessageTextOutline,
    path: "/sms",
    title: "Sms",
  });

  if (protectedItems.items.length) {
    protectedItems.items.map((item) => {
      return sections[0].items.push(item);
    });
  }

  return sections;
};
