export interface SmsFormModel {
  id?: string;
  titulo: string;
  mensagem: number;
}

export const SMS_FORM_INITIAL_STATE: SmsFormModel = {
  id: "",
  titulo: "",
  mensagem: 0,
};

export interface Passageiro {
  passageiroId: number;
  telefone: string;
}

export interface SmsPontosDeEmbarqueSubmit {
  cidadeOrigemId: number;
  eventoId: number;
  dataHoraKey: string;
}

export interface SmsSubmitModel {
  mensagem: string;
  eventoId: number[];
  pontosEmbarque: SmsPontosDeEmbarqueSubmit[];
  passageirosSelecionados: Passageiro[];
  todoFiltro: boolean;
}

export const INIT_SMS_FORM_DATA: SmsSubmitModel = {
  mensagem: "",
  eventoId: [],
  pontosEmbarque: [],
  passageirosSelecionados: [],
  todoFiltro: false,
};
