import { OptSideLayoutContentCustom } from "../../components/OptSideLayoutContentCustom/OptSideLayoutContentCustom";

const Home = () => {
  return (
    <OptSideLayoutContentCustom>
      <div></div>
    </OptSideLayoutContentCustom>
  );
};

export default Home;
