import { Button } from "@mui/material";
import styled from "styled-components";
import { Colors } from "./../../../shared/colors";
import AsyncSelect from "react-select/async";

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const ButtonAction = styled(Button)`
  background-color: ${Colors.primary};
  color: ${Colors.white};
  font-family: "IBM Plex Sans";
  &:hover {
    background-color: ${Colors.primaryTints.tint1};
  }
`;

export const StyledAsyncSelect = styled(AsyncSelect)`
  width: 292px;
  padding-bottom: 4px;
  margin-bottom: 10px;

  & > div {
    min-height: 54px;
  }

  /* & > div[id*="listbox"] {
    height: 45px;
  } */
`;
