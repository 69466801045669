import { yupResolver } from "@hookform/resolvers/yup";
import { mdiChevronLeft } from "@mdi/js";
import { Button, Divider } from "@mui/material";
import { OptActionButton, OptBackdrop } from "@optsol/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { FooterToolbar } from "../../../components/FooterToolbar";
import { SmsSubmitModel } from "../../../models/sms/smsFormModel";
import { Colors } from "../../../shared/colors";
import { FormularioSmsSchema } from "./FormularioSms.validation";
import * as S from "./styles/index";

interface Props {
  open: boolean;
  onClose: () => void;
  enviar: (data: SmsSubmitModel) => Promise<void>;
  defaultData: SmsSubmitModel;
  loading: boolean;
}

export const FormularioSms = ({
  open,
  onClose,
  defaultData,
  enviar,
  loading,
}: Props) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<SmsSubmitModel>({
    defaultValues: defaultData,
    resolver: yupResolver(FormularioSmsSchema),
  });

  console.log(watch());
  useEffect(() => {
    const newData = defaultData.pontosEmbarque.filter((element, index) => {
      return defaultData.pontosEmbarque.indexOf(element) === index;
    });

    reset({ ...defaultData, pontosEmbarque: newData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData]);

  const sxButtonSaveConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  const sxButtonCancelConfig = {
    backgroundColor: Colors.gray10,
    color: Colors.gray4,
    height: "38px",
    "&:hover": {
      backgroundColor: Colors.gray9,
    },
  };

  return (
    <form>
      <S.StyledDrawer anchor="right" open={open} onClose={onClose}>
        <OptBackdrop open={loading} />
        <S.CabecalhoContainer>
          <OptActionButton
            onClick={onClose}
            startIcon={{ path: mdiChevronLeft, color: Colors.black1 }}
          >
            <S.SpanText>{"Enviar SMS"}</S.SpanText>
          </OptActionButton>
        </S.CabecalhoContainer>
        <Divider />
        <S.GridContent container rowSpacing={1} columns={12} px={1.5}>
          <S.GridItem item xs={12}>
            <Controller
              name="mensagem"
              control={control}
              render={({ field: { onChange, value } }) => (
                <S.TextFieldCustom
                  label="Mensagem"
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMessage error={errors.mensagem} />
          </S.GridItem>
        </S.GridContent>
        <FooterToolbar style={{ marginTop: "auto", padding: "20px" }}>
          <Button size="large" onClick={onClose} sx={sxButtonCancelConfig}>
            Cancelar
          </Button>
          <Button
            size="large"
            onClick={handleSubmit(enviar)}
            sx={sxButtonSaveConfig}
          >
            Enviar
          </Button>
        </FooterToolbar>
      </S.StyledDrawer>
    </form>
  );
};
