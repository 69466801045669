import { mdiSend } from "@mdi/js";
import Icon from "@mdi/react";
import { Box, Button, FormControlLabel, Switch, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptGridRef,
  OptGridRequest,
  OptSelectionOption,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { OptGridContainer } from "../../components/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../components/OptSideLayoutContentCustom/OptSideLayoutContentCustom";
import {
  ListarEventosSearchResonse,
  ListarPontosEmbarqueSearchResonse,
} from "../../models/dtos/SmsSearchResponse";
import { ListaDePassageirosSearchRequest } from "../../models/search/PaginatedSearchRequest";
import {
  INIT_SMS_FORM_DATA,
  Passageiro,
  SmsPontosDeEmbarqueSubmit,
  SmsSubmitModel,
} from "../../models/sms/smsFormModel";
import { Routes } from "../../routes";
import { useSmsService } from "../../services/sms.service";
import { Colors } from "../../shared/colors";
import { theme } from "../../shared/theme";
import { SearchRequest } from "../../shared/types/SearchRequest";
import { FormularioSms } from "./FormularioSms/FormularioSms";
import { GridSmsView } from "./GridSmsView";
import * as S from "./styles/index";

export const GridSms = () => {
  const [smsEventos, setSmsEventos] = useState<OptSelectionOption[]>([]);
  const [smsPontos, setSmsPontos] = useState<OptSelectionOption[]>([]);
  const [selecionarTodos, setSelecionarTodos] = useState(false);
  const [selecoesDoGrid, setSelecoesDoGrid] = useState(INIT_SMS_FORM_DATA);
  const [drawerFormularioAberto, setDrawerFormularioAberto] = useState(false);
  const [enviarLoading, setEnviarLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const { obterEventosSms, obterPontosSms, listarPassageiros, enviarSms } =
    useSmsService();

  const gridRef = useRef<OptGridRef>(null);

  function recarregarGrid() {
    if (gridRef.current) {
      gridRef.current.refresh();
    }
  }

  // Listar opções para EventosSelect
  async function obterSelectEventos(entradaDoSelect: string) {
    const response: ListarEventosSearchResonse[] = await obterEventosSms({
      termo: entradaDoSelect,
    });

    const data: OptSelectionOption[] = response.map((x) => ({
      ...x,
      value: x.eventoId.toString(),
      label: `${x.eventoId} - ${x.eventoDescricao}`,
    }));

    return data;
  }

  // Listar opções para PontosEmbarqueSelect
  async function obterSelectPontos(entradaDoSelect: string) {
    const eventosArray: number[] = [];
    smsEventos.map((evento) => eventosArray.push(parseInt(evento.value)));

    const response: ListarPontosEmbarqueSearchResonse[] = await obterPontosSms({
      eventoIds: eventosArray,
    });

    const data: OptSelectionOption[] = response.map((x) => ({
      ...x,
      value: x.dataHoraKey,
      label: `${x.eventoId} - ${x.descricao}`,
    }));

    return data;
  }

  const carregarGrid = (query: OptGridRequest) => {
    const pontosEmbarqueSearch = (
      smsPontos as unknown as ListarPontosEmbarqueSearchResonse[]
    ).map((obj) => ({
      cidadeOrigemId: obj.cidadeOrigemId,
      eventoId: obj.eventoId,
      dataHoraKey: obj.dataHoraKey,
    }));

    const request: SearchRequest<ListaDePassageirosSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: {
        pontosEmbarque: pontosEmbarqueSearch,
      },
    };

    return listarPassageiros(request);
  };

  const atualizarSelecoesDoGrid = (
    eventos: number[],
    pontos: SmsPontosDeEmbarqueSubmit[],
    passageiros: Passageiro[]
  ) => {
    setSelecoesDoGrid({
      ...selecoesDoGrid,
      eventoId: eventos,
      pontosEmbarque: pontos,
      passageirosSelecionados: passageiros,
    });
  };

  function abrirDrawerFormulario(): void {
    setDrawerFormularioAberto(true);
  }

  function fecharDrawerFormulario(): void {
    if (selecionarTodos) {
      const element = document.getElementById("switch-selecionar-todos");
      element?.click();
    }
    setDrawerFormularioAberto(false);
  }

  const enviar = async (data: SmsSubmitModel) => {
    setEnviarLoading(true);
    try {
      await enviarSms(data);
      enqueueSnackbar(`SMS enviado com sucesso!`, {
        variant: "success",
      });
      setSmsEventos([]);
      setSmsPontos([]);
      setEnviarLoading(false);
      setDrawerFormularioAberto(false);
      recarregarGrid();
    } catch {
      setEnviarLoading(false);
    }
  };

  useEffect(() => {
    if (smsEventos.length && smsPontos.length) {
      recarregarGrid();
    }

    if (!smsEventos.length && !smsPontos.length) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        eventoId: [],
        pontosEmbarque: [],
      });
    }

    if (!smsEventos.length && smsPontos.length) {
      setSmsPontos([]);
      setSelecoesDoGrid({ ...selecoesDoGrid, eventoId: [] });
    }

    if (smsEventos.length && !smsPontos.length) {
      setSelecoesDoGrid({ ...selecoesDoGrid, pontosEmbarque: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsEventos, smsPontos]);

  useEffect(() => {
    if (selecionarTodos) {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        pontosEmbarque: (
          smsPontos as unknown as ListarPontosEmbarqueSearchResonse[]
        ).map((obj) => ({
          cidadeOrigemId: obj.cidadeOrigemId,
          eventoId: obj.eventoId,
          dataHoraKey: obj.dataHoraKey,
        })),
        passageirosSelecionados: [],
        todoFiltro: true,
      });
      abrirDrawerFormulario();
    } else {
      setSelecoesDoGrid({
        ...selecoesDoGrid,
        eventoId: [],
        passageirosSelecionados: [],
        pontosEmbarque: [],
        todoFiltro: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecionarTodos]);

  return (
    <>
      <FormularioSms
        open={drawerFormularioAberto}
        onClose={() => fecharDrawerFormulario()}
        enviar={enviar}
        defaultData={selecoesDoGrid}
        loading={enviarLoading}
      />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Routes.Home}
          title="SMS"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <FormControlLabel
              sx={{ marginBottom: "6px", paddingLeft: 0.5 }}
              label="Enviar para todos"
              control={
                <Switch
                  disabled={!smsPontos.length}
                  value={selecionarTodos}
                  id={"switch-selecionar-todos"}
                  onChange={(e) => {
                    setSelecionarTodos(!selecionarTodos);
                  }}
                />
              }
            />

            <S.ButtonSection>
              <Tooltip title={"Enviar SMS"}>
                <Button
                  style={{ marginRight: "-15px", marginBottom: "11px" }}
                  placeholder="Enviar SMS"
                  size="large"
                  onClick={
                    selecoesDoGrid.pontosEmbarque.length
                      ? abrirDrawerFormulario
                      : () => {}
                  }
                >
                  <Icon path={mdiSend} size={1} color={Colors.primary} />
                </Button>
              </Tooltip>
              <S.StyledAsyncSelect
                placeholder="Eventos"
                noOptionsMessage={() => "Sem opções pré-definidas"}
                isMulti
                cacheOptions
                defaultOptions
                value={smsEventos}
                loadOptions={obterSelectEventos}
                onChange={(e: any) => {
                  setSmsEventos(e as OptSelectionOption[]);
                }}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray10,
                    boxShadow: state.isFocused
                      ? `0 0 0 1px ${theme.light?.primary}`
                      : "",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? theme.light?.primary
                        : Colors.gray8,
                    },
                  }),
                }}
              />
              <S.StyledAsyncSelect
                // key={JSON.stringify(smsEventos)}
                key={
                  !smsEventos.length ? JSON.stringify(smsEventos) : undefined
                }
                placeholder="Pontos de Embarque"
                noOptionsMessage={() => "Sem opções pré-definidas"}
                isMulti
                cacheOptions
                defaultOptions
                isDisabled={!smsEventos.length ? true : false}
                loadOptions={obterSelectPontos}
                onChange={(e: any) => {
                  setSmsPontos(e as OptSelectionOption[]);
                }}
                styles={{
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderColor: state.isFocused
                      ? theme.light?.primary
                      : Colors.gray10,
                    boxShadow: state.isFocused
                      ? `0 0 0 1px ${theme.light?.primary}`
                      : "",
                    "&:hover": {
                      borderColor: state.isFocused
                        ? theme.light?.primary
                        : Colors.gray8,
                    },
                  }),
                }}
              />
            </S.ButtonSection>
          </Box>
        </OptGridContainer>
        <GridSmsView
          ref={gridRef}
          carregar={carregarGrid}
          atualizarSelecoesDoGrid={atualizarSelecoesDoGrid}
          selecionarTodos={selecionarTodos}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
