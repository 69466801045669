import { yupResolver } from "@hookform/resolvers/yup";
import { mdiDeleteOutline, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Button, Tooltip } from "@mui/material";
import {
  OptActionToolbar,
  OptBackdrop,
  OptConfirmationDialog,
  OptGridRef,
  OptGridRequest,
  OptSearchField,
} from "@optsol/react";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { OptGridContainer } from "../../components/OptGridContainer";
import { OptSideLayoutContentCustom } from "../../components/OptSideLayoutContentCustom/OptSideLayoutContentCustom";
import { ItemSearchResponse } from "../../models/dtos/ItemSearchResponse";
import {
  ItemFormModel,
  ITEM_FORM_INITIAL_STATE,
} from "../../models/Item/ItemFormModel";
import { ItemModel } from "../../models/Item/itemModel";
import { PaginatedSearchRequest } from "../../models/search/PaginatedSearchRequest";
import { Routes } from "../../routes";
import { useItensService } from "../../services/itens.service";
import { Colors } from "../../shared/colors";
import { SearchRequest } from "../../shared/types/SearchRequest";
import { FormularioItem } from "./FormularioItem/FormularioItem";
import { FormularioItemSchema } from "./FormularioItem/FormularioItem.validation";
import { GridItensView } from "./GridItensView";
import * as S from "./styles/index";

export const GridItens = () => {
  const [viagemSearch, setViagemSearch] = useState("");
  const [drawerFormulario, setDrawerFormularioAberto] = useState(false);
  const [edicao, setEdicao] = useState(false);

  const useItemForm = useForm<ItemFormModel>({
    resolver: yupResolver(FormularioItemSchema),
  });
  const [salvarLoading, setSalvarLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [confirmandoExclusao, setConfirmandoExclusao] =
    useState<boolean>(false);

  const [item, setItem] = useState<ItemFormModel>(ITEM_FORM_INITIAL_STATE);

  const { buscarGridItensPaginated, salvarItem, removerItem, editarItem } =
    useItensService();
  const { enqueueSnackbar } = useSnackbar();

  const carregar = async (
    query: OptGridRequest,
    itemSearchRequest: PaginatedSearchRequest = {
      termo: viagemSearch,
    }
  ) => {
    const request: SearchRequest<PaginatedSearchRequest> = {
      page: query.page,
      pageSize: query.pageSize,
      search: itemSearchRequest,
    };
    return buscarGridItensPaginated(request);
  };

  function onRowClick(rowData?: ItemModel) {
    if (rowData) {
    }
  }

  const gridRef = useRef<OptGridRef>(null);
  function recarregar() {
    gridRef.current?.refresh();
  }

  async function salvar(value: ItemFormModel) {
    setSalvarLoading(true);
    try {
      const estaEditando = !!value.id;
      const operacao = estaEditando ? "editado" : "criado";
      if (!estaEditando) {
        await salvarItem(value);
      } else {
        await editarItem(value.id!, value);
      }

      setSalvarLoading(false);
      enqueueSnackbar(`Registro ${operacao} com sucesso!`, {
        variant: "success",
      });
      setDrawerFormularioAberto(false);
      recarregar();
    } catch {
      setSalvarLoading(false);
    }
  }

  function abrirDrawerFormulario(): void {
    useItemForm.reset(ITEM_FORM_INITIAL_STATE);
    setDrawerFormularioAberto(true);
    if (item.id) {
      setEdicao(true);
    } else {
      setEdicao(false);
    }
  }

  async function onClickDeleteItemHandler() {
    setConfirmandoExclusao(false);
    setDeleteLoading(true);
    try {
      await removerItem(item.id!);
      setDeleteLoading(false);
      enqueueSnackbar(`Registro excluído com sucesso!`, { variant: "success" });
      setDrawerFormularioAberto(false);
      setItem(ITEM_FORM_INITIAL_STATE);
      recarregar();
    } catch {
      setDeleteLoading(false);
    }
  }

  async function onClickDeleteItem(rowData: ItemSearchResponse) {
    setItem(rowData);
    setConfirmandoExclusao(true);
  }

  function procurarViagem(data?: string) {
    setViagemSearch(data ?? "");
  }

  const sxButtonConfig = {
    backgroundColor: Colors.primary,
    color: Colors.white,
    height: "38px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: Colors.primaryTints.tint1,
    },
  };

  async function onClickEditItem(rowData: ItemSearchResponse) {
    const [diaInicio, mesInicio, anoInicio] = rowData.dataInicio.split("/");
    const [diaFim, mesFim, anoFim] = rowData.dataFim.split("/");

    const itemForm: ItemFormModel = {
      dataInicio: new Date(mesInicio + "/" + diaInicio + "/" + anoInicio),
      dataFim: new Date(mesFim + "/" + diaFim + "/" + anoFim),
      valor: rowData.valor,
      descricao: rowData.descricao,
      codigo: rowData.codigo,
      id: rowData.id,
    };
    useItemForm.reset(itemForm);
    setDrawerFormularioAberto(true);
    if (itemForm.id) {
      setEdicao(true);
    }
  }

  function cancelarExclusao() {
    setConfirmandoExclusao(false);
  }

  useEffect(() => {
    if (gridRef.current) {
      recarregar();
    }
  }, [viagemSearch]);

  return (
    <>
      <OptConfirmationDialog
        open={confirmandoExclusao}
        title="Excluir cupom"
        icon={{ path: mdiDeleteOutline, color: Colors.red }}
        onCancel={cancelarExclusao}
        onClose={cancelarExclusao}
        onConfirm={onClickDeleteItemHandler}
      >
        Deseja confirmar a exclusão do item?
      </OptConfirmationDialog>

      <FormularioItem
        open={drawerFormulario}
        onClose={() => {
          setDrawerFormularioAberto(false);
        }}
        salvar={salvar}
        useParamForm={useItemForm}
        loading={salvarLoading}
        edicao={edicao}
      />
      <OptBackdrop open={deleteLoading} />
      <OptSideLayoutContentCustom>
        <OptActionToolbar
          goBackRoute={Routes.Home}
          title="Cupom"
          background={Colors.white}
          color={Colors.gray1}
          clearMargin
        ></OptActionToolbar>
        <OptGridContainer>
          <S.ButtonSection>
            <Tooltip title={"Cadastrar cupom"}>
              <Button
                style={{ marginRight: "5px", marginBottom: "11px" }}
                placeholder="Cadastrar cupom"
                size="large"
                onClick={abrirDrawerFormulario}
              >
                <Icon path={mdiPlusCircle} size={1} color={Colors.primary} />
              </Button>
            </Tooltip>
            <OptSearchField
              placeholder="Buscar cupom"
              onSearch={procurarViagem}
              width={300}
            />
          </S.ButtonSection>
        </OptGridContainer>
        <GridItensView
          onRowClick={onRowClick}
          carregar={carregar}
          abrirDrawerFormulario={abrirDrawerFormulario}
          onClickDelete={onClickDeleteItem}
          onClickEdit={onClickEditItem}
          gridRef={gridRef}
        />
      </OptSideLayoutContentCustom>
    </>
  );
};
