import React from 'react';
import { FieldError } from 'react-hook-form';

interface Props {
  error?: FieldError;
}

export const ErrorMessage = ({ error }: Props) => {
  return error ? <p style={{ color: 'red', marginTop: '4px' }}>{error.message}</p> : null;
};
