import { DialogContent, TextField } from "@mui/material";
import styled from "styled-components";

export const StyledDialogContent = styled(DialogContent)`
  min-width: 400px;
  display: flex;
  flex-direction: column;

  & .MuiFormControl-root:not(:first-child) {
    margin-top: 16px;
  }
`;

export const CheckListContent = styled.div`
  margin-left: 1.5em;
`;

export const LiCheckPassWord = styled.li<{ isValid: boolean }>`
  color: ${(p) => (p.isValid ? "green" : "red")};
`;

export const UlChekPassWord = styled.ul`
  text-align: left;
  font-size: 14px;
  padding: 5px;
`;
export const SenhaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextFieldCustom = styled(TextField)`
  width: 90%;
  margin-top: 10px;
`;
