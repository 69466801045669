import { Role } from "../viagem/ViagemFormModel";

export interface ViagemSearchResponse {
  id: string;
  nomeViagem: string;
  contatoNome: string;
  contatoEmail: string;
  contatoTelefone: string;
  rota: Localizacao[];
  destino: Localizacao;
  veiculo: Veiculo;
  dataHoraSaida: string;
  dataHoraRetorno: string;
  valorTotal: number;
  cupomCodigo: string;
  valorFinal: number;
  distanciaTotal: number;
  tipoPagamento: TipoPagamentoViagem;
  situacaoPagamentoDescricao: string;
  usuario: Usuario;
}
export interface Localizacao {
  endereco: string;
  id: string;
  latitude: string;
  longitude: string;
  ordem: number;
}

export enum Veiculo {
  Onibus = 1,
  Van = 2,
  MicroOnibus = 3,
}

export const VeiculoLabel = new Map<number, string>([
  [Veiculo.Onibus, "Ônibus"],
  [Veiculo.Van, "Van"],
  [Veiculo.MicroOnibus, "Micro Ônibus"],
]);

export interface Cupom {
  id: string;
  valor: number;
  descricao: string;
  codigo: string;
  dataInicio: string;
  dataFim: string;
}

export interface Usuario {
  acessoEnum: Role;
  acesso: string;
  id: string;
  nome: string;
  email: string;
}

export interface Pagamento {
  tipo: TipoPagamentoViagem;
}

export enum TipoPagamentoViagem {
  Pix = 1,
  CartaoCredito = 2,
}

export const PagamentoLabel = new Map<number, string>([
  [TipoPagamentoViagem.Pix, "Pix"],
  [TipoPagamentoViagem.CartaoCredito, "Cartão de Crédito"],
]);
