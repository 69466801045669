export const Colors = {
  primary: "#F6B31E", //

  primaryTints: {
    tint1: "#a68947",
    tint2: "#a68947",
    tint3: "#5B8DEC",
    tint4: "#729DEE",
    tint5: "#88ADF2",
    tint6: "#A0BEF3",
  },

  secondary: "#42545C",

  grayDefault: "#EAEEF4", //
  gray20p: "rgb(234, 238, 244, .2)", //
  gray40p: "rgb(234, 238, 244, .4)", //
  gray60p: "rgba(234, 238, 244, 0.6)", //

  ciano: "#008B8B", //
  ciano1: "#22BABA",

  gray: "#25282D",
  gray1: "#333333",
  gray2: "#454854",
  gray3: "#757575",
  gray4: "#828282",
  gray5: "#B0B5C1",
  gray6: "#b5b5b5",
  gray7: "#cccccc",
  gray8: "#d9d9d9",
  gray9: "#ebebeb",
  gray10: "#EAEEF4",
  gray11: "#f5f5f5",

  white: "#FFFFFF",

  blue1: "#2F80ED",
  blue2: "#2D9CDB",
  blue3: "#56CCF2",
  blue4: "#004E95",
  blue5: "#4169E1",
  blue6: "#6e9ef5",
  blue7: "#90bffc",
  blue8: "#BEC7F6",
  blue9: "#e6e6f5",

  green1: "#7ec47e",
  green2: "#27AE60",
  green3: "#47DE87",
  green4: "#d6f5d6",
  red: "#E20000",
  red1: "#EB5757",
  red2: "#ed6d6d",
  red3: "#e88787",
  red4: "#e39494",
  red5: "#e3a6a6",
  yellow: "#F2C94C",
  orange: "#fcd181",

  running: "#FFAE4F",
  done: "#5AC55E",
  done2: "#4C8DF9",
  finished: "#2E8B57",

  black1: "#000000",
} as const;
