import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/auth";
import {
  LoginFormModel,
  LOGIN_FORM_INITIAL_STATE,
} from "../../models/login/loginFormModel";
import bannerUrl from "../../shared/images/login.png";
import { LoginForm } from "./LoginForm";
import * as S from "./styles/index";

export const Login = () => {
  const context = useAuth();
  const useLoginForm = useForm<LoginFormModel>({
    defaultValues: LOGIN_FORM_INITIAL_STATE,
  });

  function handleLogin(value: LoginFormModel) {
    context.Login(value);
  }

  return (
    <S.DivMainContent>
      <S.GridContent container rowSpacing={0} columns={12}>
        <S.GridItemFormCustom item xs={12} md={6} sm={12}>
          <S.FormTitle>
            <h1>Bem-vindo</h1>
            <span>Para acessar basta informar seu e-mail e senha.</span>
          </S.FormTitle>
          <LoginForm useParamForm={useLoginForm} logar={handleLogin} />
        </S.GridItemFormCustom>
        <S.GridItemBannerCustom item xs={0} md={6} sm={0}>
          <S.ImgSize src={bannerUrl} alt="Logo banner"></S.ImgSize>
        </S.GridItemBannerCustom>
      </S.GridContent>
    </S.DivMainContent>
  );
};
