import {
  OptGrid,
  OptGridColumn,
  OptGridOptions,
  OptGridRef,
  OptGridRequest,
  OptGridResponse,
} from "@optsol/react";
import React from "react";
import { OptGridContainer } from "../../components/OptGridContainer";
import { ListarPassageirosSearchResponse } from "../../models/dtos/SmsSearchResponse";
import { ListaDePassageirosSearchRequest } from "../../models/search/PaginatedSearchRequest";
import {
  Passageiro,
  SmsPontosDeEmbarqueSubmit,
} from "../../models/sms/smsFormModel";

interface Props {
  carregar: (
    query: OptGridRequest,
    estadiaSearchRequest?: ListaDePassageirosSearchRequest
  ) => Promise<OptGridResponse<ListarPassageirosSearchResponse>>;
  atualizarSelecoesDoGrid: (
    eventos: number[],
    pontos: SmsPontosDeEmbarqueSubmit[],
    passageiros: Passageiro[]
  ) => void;
  selecionarTodos: boolean;
}

export const GridSmsView = React.forwardRef<OptGridRef | undefined, Props>(
  ({ atualizarSelecoesDoGrid, carregar, selecionarTodos }, ref) => {
    const options: OptGridOptions = {
      search: true,
      selection: !selecionarTodos ? true : false,
      pageSize: 10,
    };

    const columns: OptGridColumn<ListarPassageirosSearchResponse>[] = [
      {
        title: "Id",
        field: "passageiroId",
        align: "left",
        hidden: true,
      },
      {
        title: "Nome do Passageiro",
        field: "nome",
        align: "left",
      },
      {
        title: "Nº de Embarque",
        field: "numeroBilhete",
        align: "left",
      },
      {
        title: "Ponto de Embarque",
        field: "pontoEmbarque",
        align: "left",
      },
      // {
      //   title: "Horário de Embarque",
      //   field: "horarioEmbarque",
      //   align: "left",
      // },
      // {
      //   title: "Enviado",
      //   field: "enviado",
      //   align: "center",
      //   render: (rowData) => {
      //     return rowData.enviado ? (
      //       <Icon path={mdiCheckBold} size={0.8} />
      //     ) : (
      //       <Icon path={mdiCheckBold} size={0.8} style={{ opacity: 0 }} />
      //     );
      //   },
      // },
    ];

    return (
      <>
        <OptGridContainer>
          <OptGrid
            title=""
            ref={ref as any}
            columns={columns}
            data={carregar}
            options={options}
            onRowClick={() => {}}
            actionsPosition={"end"}
            onSelect={(rowData) => {
              let eventoArray = rowData.map((obj) => obj.eventoId);
              let eventos = eventoArray.filter(
                (item, index) => eventoArray.indexOf(item) === index
              );

              let pontos = rowData.map((obj) => ({
                cidadeOrigemId: obj.cidadeOrigemId,
                eventoId: obj.eventoId,
                dataHoraKey: obj.dataHoraKey,
              }));

              let passageiros = rowData.map((obj) => ({
                passageiroId: obj.passageiroId,
                telefone: obj.passageiroTelefone ?? "sem telefone",
              }));

              atualizarSelecoesDoGrid(eventos, pontos, passageiros);
            }}
          />
        </OptGridContainer>
      </>
    );
  }
);
