import { Drawer, FormControl, Grid, Select, TextField } from "@mui/material";
import styled from "styled-components";
import { Colors } from "../../../../shared/colors";

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: 570px;
    display: flex;
    flex-direction: column;
  }
`;

export const CabecalhoContainer = styled.div`
  margin: 0.87em 0;
  display: flex;
`;

export const GridContent = styled(Grid)`
  margin-top: 2em;
`;

export const TextFieldCustom = styled(TextField)`
  width: 100%;
`;

export const FormControlCustom = styled(FormControl)`
  width: 100%;
`;

export const SelectCustom = styled(Select)`
  width: 100%;
`;

export const GridItem = styled(Grid)`
  padding-left: 10px;
  padding-right: 10px;
`;

export const GridItemContent = styled.div`
  display: flex;
  /* width: 95%; */
  flex-direction: column;
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2em 0;
`;

export const SearchContainer = styled.div`
  & .MuiOutlinedInput-root {
    height: 35px;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline,
  [class*="MuiInputBase-root-MuiOutlinedInput-root"]:hover.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${Colors.primary};
  }
  & div {
    margin-bottom: 0;
  }
`;

export const SpanText = styled.span`
  font-size: 16px;
`;
