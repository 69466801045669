import styled from "styled-components";
import { Colors } from "../shared/colors";

export const OptGridContainer = styled.div`
  margin-bottom: 27px;
  margin: 0 20px;
  margin-top: 20px;

  & .opt-grid > div:first-child {
    padding: 6px 24px;
  }

  & .opt-grid > div.pagination {
    border-top: 0.5px solid ${Colors.gray9};
    height: 54px;
  }

  & .opt-grid > div.tableWrap > table > thead > tr:first-child > th {
    text-align: center;
  }
`;
