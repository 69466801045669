import { ItemSearchResponse } from "../models/dtos/ItemSearchResponse";
import { ItemFormModel } from "../models/Item/ItemFormModel";
import { useApi } from "../shared/hooks/api";
import { SearchRequest } from "../shared/types/SearchRequest";

export const useItensService = () => {
  const { post, remove, put, gridSearch } = useApi();

  const baseApi = `/v1/cupom`;

  const salvarItem = async (data: ItemFormModel) => {
    return post(`${baseApi}`, data);
  };

  const removerItem = async (id: string) => {
    return remove(`${baseApi}/${id}`);
  };

  const editarItem = async (itemId: string, data: ItemFormModel) => {
    return put(`${baseApi}/${itemId}`, data);
  };

  const buscarGridItensPaginated = async (data: SearchRequest<{}>) => {
    return gridSearch<ItemSearchResponse>(`${baseApi}/paginated`, data);
  };

  return {
    salvarItem,
    removerItem,
    editarItem,
    buscarGridItensPaginated,
  };
};
