import {
  ListarEventosSearchResonse,
  ListarPassageirosSearchResponse,
  ListarPontosEmbarqueSearchResonse,
} from "../models/dtos/SmsSearchResponse";
import {
  PaginatedSearchRequest,
  PontosDeEmbarqueSearchRequest,
} from "../models/search/PaginatedSearchRequest";
import { SmsSubmitModel } from "../models/sms/smsFormModel";
import { useApi } from "../shared/hooks/api";
import { SearchRequest } from "../shared/types/SearchRequest";

export const useSmsService = () => {
  const { post, gridSearch } = useApi();

  const baseApi = "/pwa";

  const obterEventosSms = async (termo: PaginatedSearchRequest) => {
    const result = await post<ListarEventosSearchResonse[]>(
      `${baseApi}/listar-eventos`,
      termo
    );
    return result.data;
  };

  const obterPontosSms = async (termo: PontosDeEmbarqueSearchRequest) => {
    const result = await post<ListarPontosEmbarqueSearchResonse[]>(
      `${baseApi}/listar-pontos-embarque-filtro`,
      termo
    );
    return result.data;
  };

  const listarPassageiros = async (data: SearchRequest<{}>) => {
    return gridSearch<ListarPassageirosSearchResponse>(
      `${baseApi}/listar-passageiros-pontos-embarque`,
      data
    );
  };

  const enviarSms = async (data: SmsSubmitModel) => {
    return post(`${baseApi}/enviar-sms`, data);
  };

  return {
    obterEventosSms,
    obterPontosSms,
    listarPassageiros,
    enviarSms,
  };
};
