import {
  OptSideLayoutPortalContainer,
  OptSideLayoutPortalContent
} from "@optsol/react";
import { Route, Switch } from "react-router-dom";
import { Routes } from ".";
import { GridItens } from "../pages/ListaItens/GridItens";

export const ItensRoutes = () => {

  return (
    <OptSideLayoutPortalContainer>
      <OptSideLayoutPortalContent>
        <Switch>
          <Route exact path={Routes.Itens.Principal}>
            <GridItens />
          </Route>
        </Switch>
      </OptSideLayoutPortalContent>
    </OptSideLayoutPortalContainer>
  );
};
